* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-size: 14px;
	font-family: "Poppins", sans-serif !important;
}

a {
	text-decoration: none;
}

.logoWrapper {
	width: 123px;
	height: 90px;
	margin: 15px auto 15px;
}

.closeIcon {
	position: absolute;
	top: 16px;
	right: 24px;
	cursor: pointer;
}

::-webkit-scrollbar,
.MuiDataGrid-virtualScroller::-webkit-scrollbar {
	width: 3px;
	height: 1px;
}

/* Track */
::-webkit-scrollbar-track,
.MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
	border-radius: 12px;
	background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb,
.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
	border-radius: 12px;
	background: rgba(0, 0, 0, 0.1);
}

.courses {
	border: 0 !important;
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;
}

.recharts-text {
	fill: #767d90;
}

.recharts-text tspan {
	font-size: 12px;
}

.recharts-cartesian-axis-line {
	stroke: #f3f2f7;
}

.recharts-cartesian-axis-tick-line {
	stroke: transparent;
}

.read {
	color: #0f4590;
	font-size: 16px;
	text-decoration: underline;
	cursor: pointer;
	width: 100px;
}

.rbc-month-view {
	border: none !important;
}

.rbc-header + .rbc-header {
	border-left: none !important;
}

.rbc-header {
	font-size: 16px !important;
	color: #767d90;
	min-height: 40px !important;
	line-height: 40px !important;
	font-weight: 400 !important;
	text-transform: uppercase;
	border-bottom: 1px solid #e2e3e4 !important;
}

.rbc-off-range-bg {
	background: #fff !important;
}

.rbc-row-bg {
	border-right: 1px solid #e2e3e4;
}

.rbc-day-bg {
	border-left: 1px solid #e2e3e4 !important;
}

.rbc-month-row {
	border-bottom: 1px solid #e2e3e4 !important;
	min-height: 100px;
}

.rbc-month-row + .rbc-month-row {
	border-top: 0 !important;
}

.rbc-date-cell {
	padding: 15px 0 0 15px !important;
	text-align: left !important;
}

.rbc-toolbar .rbc-toolbar-label {
	font-size: 22px;
	font-weight: 600;
	color: #1e1e1e;
}

.rbc-toolbar {
	margin-bottom: 20px !important;
}

.rbc-toolbar button {
	border-color: #ededed !important;
	color: #767d90;
}

.rbc-toolbar button.rbc-active {
	background-color: #f9f9f9 !important;
	box-shadow: none !important;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
	background-color: #e3f8f9 !important;
	color: #03989e !important;
	border-radius: 0px !important;
	border-left: 2px solid #03989e !important;
	padding: 6px !important;
}

.rbc-day-slot .rbc-events-container {
	margin-right: 0 !important;
}

.rbc-day-slot .rbc-event {
	padding: 6px !important;
}

.rbc-row-segment {
	padding: 0 1px 0 0 !important;
}

.rbc-today {
	background-color: #2b2a69 !important;
}
.rbc-date-cell.rbc-now {
	color: #fff;
}

-a[href^="http://maps.google.com/maps"]
{
	display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
	display: none !important;
}
.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
	display: none;
}
.gmnoprint div {
	background: none !important;
}

.react-tel-input .special-label {
	display: none !important;
}

.react-tel-input .country-list .search {
	padding: 10px 10px 6px 10px !important;
}

/* .react-tel-input .form-control {
	height: 48px !important;
	border: 1px solid #e2e3e4 !important;
	border-radius: 6px !important;
	background: #f9f9f9 !important;
	width: 100% !important;
}
 */

.react-tel-input .country-list .search-box {
	border: 1px solid #e2e3e4 !important;
	border-radius: 6px !important;
	background: #f9f9f9 !important;
	width: 100% !important;
	margin-left: 0 !important;
}

.bigForm {
	height: 52px !important;
	border: 1px solid #eee !important;
	border-radius: 4px !important;
	background: #f9f9f9 !important;
	width: 100% !important;
}

.smallForm {
	height: 48px !important;
	border: 1px solid #e2e3e4 !important;
	border-radius: 6px !important;
	background: #f9f9f9 !important;
	width: 100% !important;
}

.errorInput {
	border-color: #d32f2f !important;
}

.miniChart {
	font-size: 10px;
	width: 10px;
	overflow: hidden;
	text-overflow: ellipsis;
}
