.test {
  background-color: red;
}

.sc-aXZVg {
  width: 100%;
}

.file {
  display: none;
}

.container {
  background-color: white;
  padding: 26px 26px 19px 26px;
  text-align: center;
}

.month {
  color: #767D90;
  font-size: 11px;
}

.monthValue {
  color: #293277;
  font-size: 18px;
}

.courseGraphContainer {
  background-color: white;
  padding: 4px 14px 6px 14px;
  text-align: center;
}

.courseMonth {
  color: #767D90;
  font-size: 10px;
}

.courseMonthValue {
  color: #293277;
  font-size: 13px;
  font-weight: 600;
}

.salesGraphContainer {
  background-color: white;
  padding: 7px 18px 8px 18px;
  text-align: center;
}

.salesMonth {
  color: #767D90;
  font-size: 11px;
}

.salesMonthValue {
  color: #2B2A69;
  font-size: 16px;
  font-weight: 600;
}

.apexcharts-text tspan {
  font-size: 12px;
  /* max-width: 20px;
  text-overflow: ellipsis;
  overflow: hidden; */
}
.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: #EEE !important
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 0;
  background: #ddd;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #2B2A69;
}